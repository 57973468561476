import React from "react"
import {graphql} from "gatsby"
import Barcode from "react-barcode"
import {Link} from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image/withIEPolyfill"
import CouponLegal from "../../components/couponLegal"

export const query = graphql`
  query {
    file(relativePath: { eq: "coupons/sandwich_1.jpg" }) {
      childImageSharp {                
        fluid(maxWidth: 768) {        
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const DailyDealsFridayPage = ({data}) => (
    <Layout>
        <SEO title="Daily Deal - Friday"/>

        <div className="container my-3">
            <h1 className="text-center">Friday Deal</h1>
            <h2 className="text-primary text-center">Get a Classic Box Lunch for $9.99</h2>
        </div>

        <div className="container-fluid p-0">
            <Img fluid={data.file.childImageSharp.fluid}/>
        </div>

        <div className="container">
            <div className="text-center">
                <Barcode format="CODE128" value="756412-005"/>
            </div>

            <div className="text-center mt-5">
                <Link className="btn btn-secondary" to="/">Back Home</Link>
            </div>

            <CouponLegal/>
        </div>
    </Layout>
);

export default DailyDealsFridayPage
